import Image from 'next/image';
import Close from '@/components/Icon/Close';
import { noticeImg } from '../Header/constants';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import CalendarModal, { ICalendarModalProps } from '../CalendarModal';
import { checkSubscribe } from '@/api/home';
import { traceEvent } from '@wk/wk-gatherer';
import { useRouter } from 'next/router';
import styles from './style.module.scss';
import cls from 'classnames';
export type ICalendarTipRef = {
  visible: boolean; // needCalendarTip为true
  close?: (visible: boolean) => void;
  showCalendarModal?: boolean; // 是否直接弹出日历扫码框
};
const CalendarTip = ({ visible, close, showCalendarModal = false }: ICalendarTipRef) => {
  const { t } = useTranslation('common');
  const [isSubscribe, setIsSubscribe] = useState(false);
  const calendarModalRef = useRef<ICalendarModalProps | null>(null);
  const { dsSource } = useRouter()?.query;

  useEffect(() => {
    showCalendarModal && calendarModalRef.current?.onShow();
  }, [showCalendarModal]);
  const getSubscribe = async () => {
    const result = await checkSubscribe();
    const isShow = !result && visible;
    setIsSubscribe(isShow);
    !isShow && onClose();
  };

  useEffect(() => {
    visible && getSubscribe();
  }, [visible]);
  const onClose = () => {
    traceEvent({
      data: {
        _event: 'O_topSubscirbeCalendarInfoClose',
        dsSource: 'webHome',
      },
    });
    close?.(visible);
    setIsSubscribe(false);
  };

  return (
    <>
      {isSubscribe && (
        <div
          className={cls(
            'fixed top-[49px] z-[10] box-border flex h-12 w-full items-center justify-center bg-[#FFF9E0] px-3 py-2 text-[12px] lg:relative lg:top-0 lg:text-[15px]',
            styles.calendarTipContainer,
          )}
          data-expose-event="O_topSubscirbeCalendarInfo"
          data-expose-data={JSON.stringify({
            dsSource: 'webHome',
          })}
        >
          <div className="flex h-full w-[960px] justify-between">
            <div className="flex items-center justify-center">
              <Image src={noticeImg} width={24} height={24} alt="WuKong" />
              <span className="ml-3 max-w-[200px] font-medium text-[#0A0A0A] lg:ml-4 lg:max-w-full">
                {t('将课程同步到日历')}
              </span>
            </div>
            <div className="flex items-center justify-center">
              <span
                className="mr-3 cursor-pointer font-medium text-[#4395DE] lg:mr-8"
                onClick={() => {
                  traceEvent({
                    data: {
                      _event: 'O_subscribeCalendarClick',
                      dsSource: 'webHome',
                    },
                  });
                  calendarModalRef.current?.onShow();
                }}
              >
                {t('日历-订阅按钮')}
              </span>
              <Close className="h-4 w-4 cursor-pointer lg:h-4 lg:w-4" color="#666" onClick={onClose} />
            </div>
          </div>
        </div>
      )}
      <CalendarModal ref={calendarModalRef} dsSource={dsSource || 'webHome'} />
    </>
  );
};

export default CalendarTip;
